import { StoreService } from './store';
function modalService() {
    const self = {
        open: {
            mediaUploader(config) {
                StoreService.update('MediaUploaderModal', {
                    show: true,
                    onDone: config.onDone,
                    onCancel: config.onCancel,
                });
            },
        },
    };
    return self;
}
export const ModalService = modalService();
