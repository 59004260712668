<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    d="M4.78 5.791v5.817h6.489V5.791h1.659V19h-1.659v-5.964H4.78V19H3.121V5.791H4.78zM15.867 6.82a4.714 4.714 0 002.457-1.071h1.344V19H18.03V7.723c-.7.448-1.421.693-2.163.735V6.82z" />
</svg>
