<script lang="ts">
  import { Meta } from '../components';
  import { LogoIcon } from '../components/icons';
</script>

<style>
  h1 {
    color: rgba(var(--grey), 1);
    font-size: 100px;
    text-align: center;
  }
</style>

<Meta title="Not found" />
<div class="auth login">
  <div class="auth--content">
    <div class="auth--bcmsLogo">
      <LogoIcon />
    </div>
    <h1>404</h1>
  </div>
</div>
