import { StoreService } from './store';
function confirmService() {
    const self = {
        async confirm(title, text, prompt) {
            return new Promise((resolve) => {
                StoreService.update('ConfirmModal', {
                    show: true,
                    title,
                    body: text,
                    prompt: prompt
                        ? {
                            invalidText: '',
                            input: prompt,
                            verify: '',
                        }
                        : undefined,
                    callback: (type) => {
                        resolve(type === 'done');
                    },
                });
            });
        },
    };
    return self;
}
export const ConfirmService = confirmService();
