import * as uuid from 'uuid';
import { PropType, } from '@becomes/cms-sdk';
function entryUtil() {
    return {
        instance(lite, languages, props) {
            if (lite) {
                const entryLite = {
                    _id: '',
                    createdAt: 0,
                    updatedAt: 0,
                    meta: languages.map((lng) => {
                        return {
                            lng: lng.code,
                            props: JSON.parse(JSON.stringify(props)),
                        };
                    }),
                    templateId: '',
                    userId: '',
                };
                return entryLite;
            }
            else {
                const entry = {
                    _id: '',
                    createdAt: 0,
                    updatedAt: 0,
                    meta: languages.map((lng) => {
                        return {
                            lng: lng.code,
                            props: JSON.parse(JSON.stringify(props)),
                        };
                    }),
                    templateId: '',
                    userId: '',
                    content: languages.map((lng) => {
                        return {
                            lng: lng.code,
                            props: [],
                        };
                    }),
                };
                return entry;
            }
        },
        instanceModified(lite, languages, props) {
            if (lite) {
                const entryLiteModified = {
                    _id: '',
                    createdAt: 0,
                    updatedAt: 0,
                    meta: {},
                    templateId: '',
                    userId: '',
                };
                languages.forEach((lng) => {
                    entryLiteModified.meta[lng.code] = JSON.parse(JSON.stringify(props));
                });
                return entryLiteModified;
            }
            else {
                const entryModified = {
                    _id: '',
                    createdAt: 0,
                    updatedAt: 0,
                    meta: {},
                    templateId: '',
                    userId: '',
                    content: {},
                };
                languages.forEach((lng) => {
                    entryModified.content[lng.code] = [];
                    entryModified.meta[lng.code] = JSON.parse(JSON.stringify(props));
                });
                return entryModified;
            }
        },
        liteToModified(entryLite) {
            const entryLiteModified = {
                _id: entryLite._id,
                createdAt: entryLite.createdAt,
                updatedAt: entryLite.updatedAt,
                meta: {},
                templateId: entryLite._id,
                userId: entryLite.userId,
            };
            entryLite.meta.forEach((item) => {
                entryLiteModified.meta[item.lng] = JSON.parse(JSON.stringify(item.props));
            });
            return entryLiteModified;
        },
        toModified(entry) {
            const entryModified = {
                _id: entry._id,
                createdAt: entry.createdAt,
                updatedAt: entry.updatedAt,
                meta: {},
                templateId: entry._id,
                userId: entry.userId,
                content: {},
                status: entry.status,
            };
            entry.meta.forEach((item) => {
                entryModified.content[item.lng] = [];
                entryModified.meta[item.lng] = JSON.parse(JSON.stringify(item.props));
                if (!entryModified.meta[item.lng][0]) {
                    entryModified.meta[item.lng][0] = {
                        array: false,
                        label: 'Title',
                        name: 'title',
                        required: true,
                        type: PropType.STRING,
                        value: [''],
                    };
                }
                if (!entryModified.meta[item.lng][1]) {
                    entryModified.meta[item.lng][1] = {
                        array: false,
                        label: 'Slug',
                        name: 'slug',
                        required: true,
                        type: PropType.STRING,
                        value: [''],
                    };
                }
            });
            entry.content.forEach((item) => {
                entryModified.content[item.lng] = JSON.parse(JSON.stringify(item.props));
            });
            return entryModified;
        },
        fromModified(entryModified) {
            const entry = {
                _id: entryModified._id,
                createdAt: entryModified.createdAt,
                updatedAt: entryModified.updatedAt,
                meta: [],
                templateId: entryModified._id,
                userId: entryModified.userId,
                content: [],
                status: entryModified.status,
            };
            for (const key in entryModified.meta) {
                entry.meta.push({
                    lng: key,
                    props: entryModified.meta[key],
                });
            }
            for (const key in entryModified.content) {
                entry.content.push({
                    lng: key,
                    props: entryModified.content[key],
                });
            }
            return entry;
        },
        contentSection: {
            createPrimary(type) {
                const value = {
                    text: '',
                    ops: [
                        {
                            insert: '',
                        },
                        {
                            insert: '\n',
                        },
                    ],
                };
                return {
                    array: false,
                    label: '',
                    name: uuid.v4(),
                    required: true,
                    type,
                    value,
                };
            },
            createWidget(widget) {
                const value = {
                    _id: widget._id,
                    props: JSON.parse(JSON.stringify(widget.props)),
                };
                return {
                    array: false,
                    label: '',
                    name: uuid.v4(),
                    required: true,
                    type: PropType.WIDGET,
                    value,
                };
            },
        },
    };
}
export const EntryUtil = entryUtil();
