<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    d="M15.536 8.464a1 1 0 010 1.415l-5.657 5.657a1 1 0 01-1.415-1.415l5.657-5.657a1 1 0 011.415 0z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M8.464 8.464a1 1 0 011.415 0l5.657 5.657a1 1 0 11-1.415 1.415L8.464 9.879a1 1 0 010-1.415z"
    clip-rule="evenodd" />
</svg>
