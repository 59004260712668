<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v3a1 1 0 11-2 0V5H5v2a1 1 0 01-2 0V4z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M8 20a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M12 3a1 1 0 011 1v16a1 1 0 11-2 0V4a1 1 0 011-1z"
    clip-rule="evenodd" />
</svg>
