<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    d="M2.6 6.42v5.54h6.18V6.42h1.58V19H8.78v-5.68H2.6V19H1.02V6.42H2.6zm12.439 11.12h7.3V19h-8.88v-1.86c0-1.48.56-2.567 1.68-3.26.36-.227.76-.427 1.2-.6l2.48-1c1.28-.507 1.92-1.34 1.92-2.5 0-.64-.22-1.16-.66-1.56-.44-.413-1.06-.62-1.86-.62-.787 0-1.433.167-1.94.5-.493.333-.78.833-.86 1.5h-1.74c.067-1.093.507-1.933 1.32-2.52.813-.587 1.853-.88 3.12-.88 1.28 0 2.32.347 3.12 1.04.8.693 1.2 1.553 1.2 2.58 0 1.013-.267 1.82-.8 2.42-.52.6-1.24 1.08-2.16 1.44l-2.54 1.02c-.64.267-1.12.573-1.44.92-.307.333-.46.773-.46 1.32v.6z" />
</svg>
