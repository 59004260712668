<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  viewBox="0 0 22 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  ><path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.293 2.293A1 1 0 013 2h9a1 1 0 011 1v1a1 1 0 102 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v9a3 3 0 003 3h1a1 1 0 100-2H3a1 1 0 01-1-1V3a1 1 0 01.293-.707zM9 10a1 1 0 011-1h9a1 1 0 011 1v9a1 1 0 01-1 1h-9a1 1 0 01-1-1v-9zm1-3a3 3 0 00-3 3v9a3 3 0 003 3h9a3 3 0 003-3v-9a3 3 0 00-3-3h-9z"
  /></svg
>
