<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    d="M2.6 6.42v5.54h6.18V6.42h1.58V19H8.78v-5.68H2.6V19H1.02V6.42H2.6zm19.679 3.22c0 1.333-.733 2.26-2.2 2.78.8.227 1.447.6 1.94 1.12.493.52.74 1.187.74 2 0 1.107-.42 2-1.26 2.68-.84.667-1.947 1-3.32 1-1.373 0-2.547-.353-3.52-1.06-.96-.72-1.44-1.727-1.44-3.02h1.64c.027.853.34 1.507.94 1.96.613.453 1.387.68 2.32.68.933 0 1.653-.22 2.16-.66.52-.453.78-1.007.78-1.66 0-.667-.247-1.207-.74-1.62-.493-.427-1.293-.64-2.4-.64h-1.44v-1.26h.98c.96 0 1.72-.187 2.28-.56.56-.387.84-.9.84-1.54s-.227-1.167-.68-1.58c-.453-.413-1.053-.62-1.8-.62-.747 0-1.373.133-1.88.4-.493.253-.827.733-1 1.44h-1.64c.147-1.107.607-1.927 1.38-2.46.773-.547 1.767-.82 2.98-.82 1.227 0 2.253.327 3.08.98.84.64 1.26 1.46 1.26 2.46z" />
</svg>
