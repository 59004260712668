<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"><path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.202 2.019a1 1 0 00-.486.025A1 1 0 119.132.13 3 3 0 119.998 6h-.002L10 5v1H1a1 1 0 110-2h9.004a1 1 0 00.198-1.981zm8.587 3.01a1.5 1.5 0 00-1.353.409 1 1 0 11-1.412-1.416A3.5 3.5 0 1118.502 10H1a1 1 0 110-2h17.499a1.5 1.5 0 00.29-2.971zM12.998 12a3 3 0 11-.866 5.869 1 1 0 11.584-1.913A1 1 0 1013.004 14H1a1 1 0 110-2h11.998z"
    fill="#000" /></svg>
