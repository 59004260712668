<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    d="M10.502 1.403a3 3 0 012.996 0h.002l7 4A3 3 0 0122 8V16a3 3 0 01-1.5 2.595l-.004.002-6.996 3.998-.002.001a3 3 0 01-2.996 0l-.002-.001-6.996-3.998-.004-.002A3 3 0 012 16V7.999a3 3 0 011.5-2.595l.004-.002 6.998-4zM12 3.002a1 1 0 00-.5.134l-.004.002L4.5 7.136h-.002A1 1 0 004 8.002v7.998a1 1 0 00.498.864H4.5l7 4a1 1 0 001 0l.004-.001 6.996-3.998.002-.001a1.001 1.001 0 00.498-.864V8a1 1 0 00-.498-.864l-.002-.001-7-4a1 1 0 00-.5-.134z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M2.404 6.46a1 1 0 011.367-.366L12 10.854l8.23-4.76a1 1 0 111 1.732l-8.73 5.05a1 1 0 01-1 0l-8.73-5.05a1 1 0 01-.366-1.367z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M12 11a1 1 0 011 1v10.08a1 1 0 11-2 0V12a1 1 0 011-1z"
    clip-rule="evenodd" />
</svg>
