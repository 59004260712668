<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    d="M2.6 6.42v5.54h6.18V6.42h1.58V19H8.78v-5.68H2.6V19H1.02V6.42H2.6zm18.139-.18v8.36h2.18v1.36h-2.18V19h-1.54v-3.04h-6.5v-1.22l6.58-8.5h1.46zm-6.16 8.5h4.66v-6l-4.66 6z" />
</svg>
