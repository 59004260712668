<script lang="ts">
  import {
    Button,
    ConfirmService,
    GeneralService,
    NotificationService,
    PasswordInput,
    sdk,
    StoreService,
    TextArea,
    TextInput,
    ToggleInput,
  } from '@becomes/cms-ui';
  import {
    ArrowDownIcon,
    ArrowUpIcon,
    ChevronDownIcon,
    TrashIcon,
  } from '@becomes/cms-ui/src/components/icons';
  import { createEventDispatcher } from 'svelte';
  import { BngineAddProjectModal } from '../components';
  import type { Project, ProjectModified } from '../types';

  export let projects: ProjectModified[] = [];

  const dispatch = createEventDispatcher();
  let sshKey: string = '';

  async function updateProject(projectIndex: number) {
    const projectToUpdate: ProjectModified & {
      repo: {
        sshKey?: string;
      };
    } = JSON.parse(JSON.stringify(projects[projectIndex]));
    projectToUpdate.show = undefined;
    if (sshKey !== '') {
      projectToUpdate.repo.sshKey = sshKey;
    }
    const proj: ProjectModified = await GeneralService.errorWrapper(
      async () => {
        return await sdk.send({
          url: '/plugin/bngine/project',
          method: 'PUT',
          headers: {
            Authorization: '',
          },
          data: projectToUpdate,
        });
      },
      async (result: { project: ProjectModified }) => {
        return result.project;
      },
    );
    if (!proj) {
      return;
    }
    sshKey = '';
    projects = projects.map((e, i) => {
      if (projectIndex === i) {
        return { ...proj, show: true };
      }
      return e;
    });
    NotificationService.success('Project successfully updated.');
  }
  async function addProject(project: {
    name: string;
    repo: {
      branch: string;
      name: string;
      url: string;
      sshKey: string;
    };
  }) {
    await GeneralService.errorWrapper(
      async () => {
        return await sdk.send<{ project: Project }>({
          url: '/plugin/bngine/project',
          method: 'POST',
          headers: {
            Authorization: '',
          },
          data: { ...project, vars: [], run: [] },
        });
      },
      async (result) => {
        dispatch('project', result.project);
      },
    );
  }
  async function deleteProject(id: string) {
    if (
      await ConfirmService.confirm(
        'Delete project',
        'Are you sure you want to delete this project?',
      )
    ) {
      await GeneralService.errorWrapper(
        async () => {
          await sdk.send({
            url: `/plugin/bngine/project/${id}`,
            method: 'DELETE',
            headers: {
              Authorization: '',
            },
          });
        },
        async () => {
          dispatch('remove', id);
        },
      );
    }
  }

  function addVar(projectIndex: number) {
    projects[projectIndex].vars = [
      ...projects[projectIndex].vars,
      {
        key: '',
        value: '',
      },
    ];
  }
  function addCommand(projectIndex: number) {
    projects[projectIndex].run = [
      ...projects[projectIndex].run,
      {
        command: '',
        ignoreIfFail: false,
        title: '',
      },
    ];
  }
  function removeCommand(projectIndex: number, commandIndex: number) {
    projects[projectIndex].run = projects[projectIndex].run.filter(
      (e, i) => i !== commandIndex,
    );
  }
  function moveCommand(projectIndex: number, commandIndex: number, by: number) {
    const newIndex = commandIndex + by;
    if (newIndex < 0 || newIndex + 1 > projects[projectIndex].run.length) {
      return;
    }
    const temp = projects[projectIndex].run[newIndex];
    projects[projectIndex].run[newIndex] = JSON.parse(
      JSON.stringify(projects[projectIndex].run[commandIndex]),
    );
    projects[projectIndex].run[commandIndex] = temp;
    projects[projectIndex].run = [...projects[projectIndex].run];
  }
  function removeVar(projectIndex: number, varIndex: number) {
    projects[projectIndex].vars = projects[projectIndex].vars.filter(
      (e, i) => i !== varIndex,
    );
  }
</script>

<div class="bngine--projects">
  {#if projects}
    {#each projects as project, projectIndex}
      <div class="bngine--project">
        <div class="bngine--project-toggle">
          <button
            on:click={() => {
              project.show = project.show === true ? false : true;
            }}
          >
            <span class="bngine--project-toggle-title">{project.name} </span>
            <ChevronDownIcon class={project.show ? 'show' : ''} />
          </button>
        </div>
        {#if project.show}
          <div class="bngine--project-section">
            <h4>General</h4>
            {#if project.name !== 'production' && project.name !== 'staging' && project.name !== 'preview'}
              <Button
                class="delete"
                kind="ghost"
                on:click={() => {
                  deleteProject(project._id);
                }}>Delete</Button
              >
            {/if}
            <div class="vars">
              <div class="var">
                <TextInput
                  class="name mr-10"
                  label="Name"
                  placeholder="Name"
                  value={project.name}
                  on:input={(event) => {
                    project.name = event.detail;
                  }}
                />
                <TextInput
                  class="url ml-10"
                  label="URL"
                  placeholder="URL"
                  value={project.repo.url}
                  on:input={(event) => {
                    project.repo.url = event.detail;
                  }}
                />
              </div>
              <div class="var">
                <TextInput
                  class="repo mr-10"
                  label="Repository name"
                  placeholder="Repository name"
                  value={project.repo.name}
                  on:input={(event) => {
                    project.repo.name = event.detail;
                  }}
                />
                <TextInput
                  class="branch ml-10"
                  label="Repository branch"
                  placeholder="Repository branch"
                  value={project.repo.branch}
                  on:input={(event) => {
                    project.repo.branch = event.detail;
                  }}
                />
              </div>
              <TextArea
                label="SSH key"
                placeholder="SSH key"
                on:input={(event) => {
                  sshKey = event.detail;
                }}
              />
            </div>
          </div>
          <div class="bngine--project-section">
            <h4>Variables</h4>
            <div class="vars">
              {#each project.vars as v, varIndex}
                <div class="var">
                  <TextInput
                    placeholder="Key"
                    value={v.key}
                    on:input={(event) => {
                      v.key = event.detail;
                    }}
                  />
                  <span style="margin: auto 10px; font-size: 24px;">=</span>
                  <PasswordInput
                    placeholder="Value"
                    value={v.value}
                    on:input={(event) => {
                      v.value = event.detail;
                    }}
                  />
                  <button
                    class="remove"
                    on:click={() => {
                      removeVar(projectIndex, varIndex);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              {/each}
              <Button
                kind="ghost"
                on:click={() => {
                  addVar(projectIndex);
                }}
              >
                Add variable
              </Button>
            </div>
          </div>
          <div class="bngine--project-section">
            <h4>Commands</h4>
            <div class="commands">
              {#each project.run as run, commandIndex}
                <div class="command">
                  <div class="top">
                    <TextInput
                      class="title"
                      label="Title"
                      placeholder="Title"
                      value={run.title}
                      on:input={(event) => {
                        run.title = event.detail;
                      }}
                    />
                    <div>
                      <ToggleInput
                        class="ignore"
                        label="Ignore if fail"
                        value={run.ignoreIfFail}
                        on:input={(event) => {
                          run.ignoreIfFail = event.detail;
                        }}
                      />
                      <div>
                        <button
                          class="move"
                          on:click={() => {
                            moveCommand(projectIndex, commandIndex, -1);
                          }}
                        >
                          <ArrowUpIcon />
                        </button>
                        <button
                          class="move"
                          on:click={() => {
                            moveCommand(projectIndex, commandIndex, 1);
                          }}
                        >
                          <ArrowDownIcon />
                        </button>
                        <button
                          class="remove"
                          on:click={() => {
                            removeCommand(projectIndex, commandIndex);
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <TextArea
                    class="mt-20"
                    label="Command"
                    placeholder="Command"
                    value={run.command}
                    on:input={(event) => {
                      run.command = event.detail;
                    }}
                  />
                </div>
              {/each}
              <Button
                kind="ghost"
                on:click={() => {
                  addCommand(projectIndex);
                }}
              >
                Add command
              </Button>
            </div>
          </div>
          <Button
            class="bngine--project-update"
            on:click={() => {
              updateProject(projectIndex);
            }}
          >
            Update project
          </Button>
        {/if}
      </div>
    {/each}
  {/if}
  <Button
    class="mt-20"
    on:click={() => {
      StoreService.update('BngineAddProjectModal', true);
    }}>Add new</Button
  >
</div>
<BngineAddProjectModal
  on:done={(event) => {
    addProject(event.detail);
  }}
/>
