<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    d="M21.707 1.293a1 1 0 010 1.414L20.414 4l2.293 2.293a1 1 0 010 1.414l-3.5 3.5a1 1 0 01-1.414 0L15.5 8.914l-2.746 2.747A6.5 6.5 0 012.9 20.09l-.007-.008a6.5 6.5 0 018.431-9.821l8.97-8.97a1 1 0 011.413 0zM19 5.414L16.915 7.5 18.5 9.086 20.586 7 19 5.414zM4.328 18.69a4.5 4.5 0 016.394-6.333 4.5 4.5 0 11-6.394 6.333zm0 0l.004.004-.72.695.712-.702.004.003z"
    clip-rule="evenodd" />
</svg>
