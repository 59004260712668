<script lang="ts">
  import {
    beforeUpdate,
    createEventDispatcher,
    onDestroy,
    onMount,
  } from 'svelte';
  import {
    Button,
    StoreService,
    sdk,
    GeneralService,
    ConfirmService,
  } from '@becomes/cms-ui/src';
  import type {
    Job,
    JobModified,
    JobPipeModified,
    Project,
    JobLite,
    JobStatus,
    JobPipe,
  } from '../types';
  import {
    BnginePreviewBuildsModal,
    BngineJobInfo,
    BngineJobPipe,
    BngineJobDetailsModal,
    BngineOtherProjectModal,
  } from '../components';
  import { EyeShowIcon } from '@becomes/cms-ui/src/components/icons';

  const dispatch = createEventDispatcher();
  export let projects: Project[];
  export let jobs: JobLite[] = [
    {
      _id: '50a7cd5d-e075-435e-b55c-65bda2a53363',
      createdAt: 1601901505310,
      updatedAt: 1601901634768,
      finishedAt: 1601901634768,
      inQueueFor: 1,
      repo: { name: 'pinkerton.com', branch: 'staging' },
      project: 'pinkerton-staging',
      running: false,
      status: 'SUCCESS' as any,
    },
  ];

  const bngineSocket = sdk.socket.subscribe(
    'plugin_bngine' as any,
    async (data: {
      jobId: string;
      state: string;
      inQueueFor?: number;
      pipe?: JobPipe;
      err?: string;
      out?: string;
      pipeCreatedAt?: number;
      pipeId?: string;
      pipeTitle?: string;
    }) => {
      if (runningJob) {
        switch (data.state) {
          case 'job-started':
            {
              runningJob.status = 'RUNNING' as JobStatus;
              runningJob.inQueueFor = data.inQueueFor;
            }
            break;
          case 'new-pipe':
            {
              runningJob.pipe = [...runningJob.pipe, parsePipe(data.pipe)];
            }
            break;
          case 'pipe-update-err':
            {
              for (const i in runningJob.pipe) {
                const pipe = runningJob.pipe[i];
                if (pipe.id === data.pipeId) {
                  runningJob.pipe[i].err += data.err;
                  break;
                }
              }
            }
            break;
          case 'pipe-update-out':
            {
              for (const i in runningJob.pipe) {
                const pipe = runningJob.pipe[i];
                if (pipe.id === data.pipeId) {
                  runningJob.pipe[i].out += data.out;
                  break;
                }
              }
            }
            break;
          case 'pipe-done':
            {
              const pipe = parsePipe(data.pipe);
              pipe.timeToExec = parseMillis(data.pipe.timeToExec);
              for (const i in runningJob.pipe) {
                if (pipe.id === runningJob.pipe[i].id) {
                  runningJob.pipe[i] = pipe;
                  break;
                }
              }
            }
            break;
          case 'done':
            {
              setTimeout(async () => {
                runningJob = undefined;
              }, 100);
              dispatch('new', data.jobId);
            }
            break;
        }
      } else {
        runningJob = parseJob(await getJobLite(data.jobId));
        if (!runningJob.pipe) {
          runningJob.pipe = [];
        }
      }
    },
  );
  let jobsModified: JobModified[] = [];
  let jobDetails: JobModified;
  let runningJob: JobModified;
  let productionProject: Project;
  let stagingProject: Project;
  let previewProject: Project;
  let hasRunningJob: boolean = true;

  async function startJob(
    projectName: string,
    data?: {
      branch?: string;
      vars?: Array<{
        key: string;
        value: string;
      }>;
    },
  ) {
    if (
      await ConfirmService.confirm(
        `Start build`,
        `Are you sure you want to build ${projectName}${
          data && data.branch ? ` for <strong>${data.branch}</strong>` : ''
        }?`,
      )
    ) {
      const job = await GeneralService.errorWrapper(
        async () => {
          return await sdk.send({
            url: `/plugin/bngine/job/start/${projectName}`,
            method: 'POST',
            headers: {
              Authorization: '',
            },
            data,
          });
        },
        async (result: { job: Job }) => {
          return result.job;
        },
      );
      runningJob = parseJob(job);
    }
  }
  async function getJobLite(jobId: string): Promise<JobLite> {
    return await GeneralService.errorWrapper(
      async () => {
        return await sdk.send({
          url: `/plugin/bngine/job/lite/${jobId}`,
          method: 'GET',
          headers: {
            Authorization: '',
          },
        });
      },
      async (result: { job: JobLite }) => {
        return result.job;
      },
    );
  }

  async function setJobDetails(jobLite: JobLite) {
    const job = await GeneralService.errorWrapper(
      async () => {
        return await sdk.send({
          url: `/plugin/bngine/job/${jobLite._id}`,
          method: 'GET',
          headers: {
            Authorization: '',
          },
        });
      },
      async (result: { job: Job }) => {
        return result.job;
      },
    );
    if (job) {
      jobDetails = parseJob(job);
      StoreService.update('BngineJobDetailsModal', true);
    }
  }
  function parseJob(job: JobLite | Job): JobModified {
    const jobModified: JobModified = JSON.parse(JSON.stringify(job));
    const finishedIn = job.finishedAt - job.createdAt - job.inQueueFor;
    if (finishedIn < 0) {
      jobModified.time = 'In progress';
    } else {
      jobModified.time = parseMillis(finishedIn);
    }
    if ((job as Job).pipe) {
      jobModified.pipe = (job as Job).pipe.map((pipe) => {
        return parsePipe(pipe);
      });
    }
    return jobModified;
  }
  function parsePipe(pipe: JobPipe): JobPipeModified {
    const pipeModifed: JobPipeModified = JSON.parse(JSON.stringify(pipe));
    pipeModifed.show = false;
    pipeModifed.timeToExec = parseMillis(pipe.timeToExec);
    return pipeModifed;
  }
  function parseMillis(millis: number): string {
    if (millis > 60000) {
      return `${parseInt('' + millis / 1000 / 60)}m ${
        parseInt('' + millis / 1000) - parseInt('' + millis / 1000 / 60) * 60
      }s`;
    } else {
      return `${parseInt(`${millis / 1000}`, 10)}s`;
    }
  }

  const timeInterval = setInterval(() => {
    try {
      if (runningJob) {
        runningJob.time = parseMillis(Date.now() - runningJob.createdAt);
        if (runningJob.pipe.length > 0) {
          runningJob.pipe[runningJob.pipe.length - 1].timeToExec = parseMillis(
            Date.now() - runningJob.pipe[runningJob.pipe.length - 1].createdAt,
          );
        }
      }
    } catch (error) {
      // ignore
    }
  }, 1000);
  let lastJobLength = 0;
  beforeUpdate(() => {
    if (projects) {
      productionProject = projects.find((e) => e.name === 'production');
      stagingProject = projects.find((e) => e.name === 'staging');
      previewProject = projects.find((e) => e.name === 'preview');
    }
    if (jobs && jobs.length !== lastJobLength) {
      lastJobLength = jobs.length;
      const activeJob = jobs.find((e) => e.status === 'RUNNING');
      if (activeJob) {
        runningJob = parseJob(activeJob);
        hasRunningJob = true;
      } else {
        hasRunningJob = false;
      }
      jobsModified = jobs
        .filter((e) => e.status !== 'RUNNING')
        .map((job) => parseJob(job));
    }
  });
  onMount(async () => {
    if (!runningJob) {
      const currentlyRunningJob = jobsModified.find(
        (e) => e.status === 'RUNNING',
      );
      if (currentlyRunningJob) {
        const j = await GeneralService.errorWrapper(
          async () => {
            return sdk.send({
              url: `/plugin/bngine/job/${currentlyRunningJob._id}`,
              method: 'GET',
              headers: {
                Authorization: '',
              },
            });
          },
          async (result: { job: Job }) => {
            return result.job;
          },
        );
        currentlyRunningJob.pipe = j.pipe.map((pipe) => parsePipe(pipe));
        runningJob = currentlyRunningJob;
      }
    }
  });
  onDestroy(() => {
    bngineSocket.unsubscribe();
    clearInterval(timeInterval);
  });
</script>

<div class="bngine--builds">
  {#if projects && jobsModified}
    <div class="bngine--builds-top">
      {#if stagingProject && stagingProject.run.length > 0}
        <Button
          class="staging"
          disabled={runningJob ? true : false || hasRunningJob}
          on:click={() => {
            startJob(stagingProject.name);
          }}
        >
          Staging
        </Button>
      {/if}
      {#if productionProject && productionProject.run.length > 0}
        <Button
          class="production"
          kind="secondary"
          disabled={runningJob ? true : false || hasRunningJob}
          on:click={() => {
            startJob(productionProject.name);
          }}
        >
          Production
        </Button>
      {/if}
      {#if previewProject && previewProject.run.length > 0}
        <Button
          class="preview"
          kind="ghost"
          disabled={runningJob ? true : false || hasRunningJob}
          on:click={() => {
            StoreService.update('BnginePreviewBuildsModal', true);
          }}
        >
          Previews
        </Button>
      {/if}
      {#if projects.length > 3}
        <Button
          kind="ghost"
          on:click={() => {
            StoreService.update('BngineOtherProjectsModal', true);
          }}>Other</Button
        >
      {/if}
    </div>
    {#if runningJob !== undefined}
      <div class="bngine--builds-running-job">
        <h4 class="bngine--builds-jobs-title">Running Job</h4>
        <div class="mt-20 build--running-job">
          <BngineJobInfo job={runningJob} disableDetails={true} />
          {#if runningJob.pipe}
            <BngineJobPipe jobPipe={runningJob.pipe} />
          {/if}
        </div>
      </div>
    {/if}
    <div class="bngine--builds-jobs">
      {#if jobsModified.length === 0}
        <div class="bngine--builds-jobs-none">
          There are no active or logged Jobs.
        </div>
      {:else}
        <h4 class="bngine--builds-jobs-title">Completed Jobs</h4>
        <ul class="bngine--builds-list">
          <li class="bngine--builds-list-item bngine--builds-cols">
            <div class="bngine--builds-list-number" />
            <div class="bngine--builds-list-status">Status</div>
            <div class="bngine--builds-list-duration">Duration</div>
            <div class="bngine--builds-list-branch">Branch</div>
            <div class="bngine--builds-list-project">Project</div>
            <div class="bngine--builds-list-date">Date</div>
            <div class="bngine--builds-list-time">Time</div>
            <div />
          </li>
          {#each jobsModified as job, i}
            <li class="bngine--builds-list-item bngine--builds-cols">
              <div
                class="bngine--builds-list-number
                  bngine--builds-list-item-col"
                title="{jobsModified.length - i}."
              >
                <span>{jobsModified.length - i}.</span>
              </div>
              <div
                class={`bngine--builds-list-status
                bngine--builds-list-status_${job.status.toLowerCase()}
                  bngine--builds-list-item-col`}
                data-column-name="Status"
                title={job.status}
              >
                {job.status}
              </div>
              <div
                class="bngine--builds-list-duration
                  bngine--builds-list-item-col"
                data-column-name="Duration"
                title={jobsModified[i].time}
              >
                {jobsModified[i].time}
              </div>
              <div
                class="bngine--builds-list-branch
                  bngine--builds-list-item-col"
                data-column-name="Branch"
                title={job.repo.branch}
              >
                {job.repo.branch}
              </div>
              <div
                class="bngine--builds-list-project
                  bngine--builds-list-item-col"
                data-column-name="Project"
                title={job.project}
              >
                {job.project}
              </div>
              <div
                class="bngine--builds-list-date
                  bngine--builds-list-item-col"
                data-column-name="Date"
                title={new Date(job.createdAt).toLocaleDateString()}
              >
                {new Date(job.createdAt).toLocaleDateString()}
              </div>
              <div
                class="bngine--builds-list-time
                bngine--builds-list-item-col"
                data-column-name="Time"
                title={new Date(job.createdAt).toLocaleTimeString()}
              >
                {new Date(job.createdAt).toLocaleTimeString()}
              </div>
              <div class="bngine--builds-list-eye bngine--builds-list-item-col">
                <Button
                  kind="ghost"
                  on:click={async () => {
                    setJobDetails(job);
                  }}
                >
                  <EyeShowIcon />
                </Button>
              </div>
            </li>
          {/each}
        </ul>
      {/if}
    </div>
  {/if}
</div>
<BnginePreviewBuildsModal
  on:done={(event) => {
    startJob('preview', {
      branch: event.detail.branch,
      vars: [
        {
          key: 'id',
          value: event.detail.branch,
        },
        {
          key: 'branch',
          value: event.detail.branch,
        },
      ],
    });
  }}
/>
<BngineOtherProjectModal
  projects={projects.slice(3)}
  on:done={(event) => {
    startJob(event.detail);
  }}
/>
<BngineJobDetailsModal
  job={jobDetails}
  on:cancel={() => {
    jobDetails = undefined;
  }}
  on:done={() => {
    jobDetails = undefined;
  }}
/>
