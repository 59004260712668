import { Router } from '@becomes/svelte-router';
import { sdk } from './sdk';
import { StoreService } from './store';
function entityManagerService() {
    return {
        async create(handler, label, desc, previewImage, previewScript, previewStyle) {
            const buffer = (await sdk[handler].add({
                label,
                desc,
                singleEntry: false,
                previewImage,
                previewScript,
                previewStyle,
            }));
            StoreService.update(handler, (value) => {
                value.push(buffer);
                return value;
            });
            const pathParts = window.location.pathname.split('/');
            Router.navigate([...pathParts.splice(0, pathParts.length - 1), buffer._id].join('/'));
            return buffer;
        },
        async update(handler, id, label, desc, previewImage, previewScript, previewStyle) {
            const buffer = (await sdk[handler].update({
                _id: id,
                label,
                desc,
                previewImage,
                previewScript,
                previewStyle,
            }));
            StoreService.update(handler, (value) => {
                for (const i in value) {
                    if (value[i]._id === buffer._id) {
                        value[i] = buffer;
                        break;
                    }
                }
                return value;
            });
            return buffer;
        },
        async delete(handler, id) {
            await sdk[handler].deleteById(id);
            StoreService.update(handler, (value) => {
                return value.filter((e) => e._id !== id);
            });
            const pathParts = window.location.pathname.split('/');
            Router.navigate([...pathParts.splice(0, pathParts.length - 1), '-'].join('/'));
        },
        async addProp(handler, id, prop) {
            const buffer = (await sdk[handler].update({
                _id: id,
                propChanges: [
                    {
                        add: prop,
                    },
                ],
            }));
            StoreService.update(handler, (value) => {
                for (const i in value) {
                    if (value[i]._id === buffer._id) {
                        value[i] = buffer;
                        break;
                    }
                }
                return value;
            });
            return buffer;
        },
        async updateProp(handler, id, props, data) {
            const prop = props.find((e) => e.name === data.name);
            if (!prop) {
                throw Error(`Failed to find a target property "${data.name}".`);
            }
            const buffer = (await sdk[handler].update({
                _id: id,
                propChanges: [
                    {
                        update: {
                            label: {
                                new: data.label,
                                old: prop.label,
                            },
                            move: data.move,
                            required: data.required,
                            enumItems: data.enumItems,
                        },
                    },
                ],
            }));
            StoreService.update(handler, (value) => {
                for (const i in value) {
                    if (value[i]._id === buffer._id) {
                        value[i] = buffer;
                        break;
                    }
                }
                return value;
            });
            return buffer;
        },
        async removeProp(handler, id, prop) {
            const buffer = (await sdk[handler].update({
                _id: id,
                propChanges: [
                    {
                        remove: prop.name,
                    },
                ],
            }));
            StoreService.update(handler, (value) => {
                for (const i in value) {
                    if (value[i]._id === buffer._id) {
                        value[i] = buffer;
                        break;
                    }
                }
                return value;
            });
            return buffer;
        },
    };
}
export const EntityManagerService = entityManagerService();
