<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 13 13">
  <path
    d="M1 7a1.1 1.1 0 011.555 0l3.112 3.112a1.1 1.1 0 01-1.556 1.555L1 8.556A1.1 1.1 0 011 7z" />
  <path
    d="M10.334 3.89a1.1 1.1 0 111.555 1.555l-6.222 6.222a1.1 1.1 0 01-1.556-1.555l6.223-6.223z" />
</svg>
