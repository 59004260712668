import * as uuid from 'uuid';
function tooltipService() {
    const listeners = [];
    let callback;
    const self = {
        create(_callback) {
            callback = _callback;
        },
        register(element, message, preCallbackHandler) {
            const id = uuid.v4();
            let visible = false;
            const clickHandler = () => {
                if (callback) {
                    if (preCallbackHandler) {
                        message = preCallbackHandler(message, element);
                    }
                    visible = !visible;
                    callback(visible, message, element);
                }
            };
            const enterHandler = () => {
                if (callback) {
                    if (preCallbackHandler) {
                        message = preCallbackHandler(message, element);
                    }
                    visible = true;
                    callback(true, message, element);
                }
            };
            const leaveHandler = () => {
                if (callback) {
                    if (preCallbackHandler) {
                        message = preCallbackHandler(message, element);
                    }
                    visible = false;
                    callback(false, message, element);
                }
            };
            element.addEventListener('click', clickHandler);
            element.addEventListener('mouseenter', enterHandler);
            element.addEventListener('mouseleave', leaveHandler);
            listeners.push({
                id,
                element,
                handlers: {
                    click: clickHandler,
                    enter: enterHandler,
                    leave: leaveHandler,
                },
            });
            return id;
        },
        unregister(id) {
            for (let i = 0; i < listeners.length; i++) {
                if (listeners[i].id === id) {
                    listeners[i].element.removeEventListener('click', listeners[i].handlers.click);
                    listeners[i].element.removeEventListener('mouseenter', listeners[i].handlers.enter);
                    listeners[i].element.removeEventListener('mouseout', listeners[i].handlers.leave);
                    listeners.splice(i, 1);
                    break;
                }
            }
        },
        bind(message, preCallbackHandler) {
            return (element, params) => {
                if (params && params.message) {
                    message = params.message;
                }
                const listenerId = self.register(element, message, preCallbackHandler);
                return {
                    destroy() {
                        self.unregister(listenerId);
                    },
                };
            };
        },
    };
    return self;
}
export const TooltipService = tooltipService();
