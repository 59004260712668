<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    d="M7 6a1 1 0 011-1h13a1 1 0 110 2H8a1 1 0 01-1-1z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M7 12a1 1 0 011-1h13a1 1 0 110 2H8a1 1 0 01-1-1z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M7 18a1 1 0 011-1h13a1 1 0 110 2H8a1 1 0 01-1-1z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M2 6a1 1 0 011-1h.01a1 1 0 010 2H3a1 1 0 01-1-1z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M2 12a1 1 0 011-1h.01a1 1 0 110 2H3a1 1 0 01-1-1z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M2 18a1 1 0 011-1h.01a1 1 0 110 2H3a1 1 0 01-1-1z"
    clip-rule="evenodd" />
</svg>
