<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    d="M15.293 5.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L20.586 12l-5.293-5.293a1 1 0 010-1.414z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M8.707 5.293a1 1 0 010 1.414L3.414 12l5.293 5.293a1 1 0 11-1.414 1.414l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 0z"
    clip-rule="evenodd" />
</svg>
