<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    d="M5 4a1 1 0 00-1 1v14a1 1 0 001 1h4a1 1 0 110 2H5a3 3 0 01-3-3V5a3 3 0 013-3h4a1 1 0 010 2H5z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M15.293 6.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L19.586 12l-4.293-4.293a1 1 0 010-1.414z"
    clip-rule="evenodd" />
  <path
    fill-rule="evenodd"
    d="M8 12a1 1 0 011-1h12a1 1 0 110 2H9a1 1 0 01-1-1z"
    clip-rule="evenodd" />
</svg>
