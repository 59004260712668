<script lang="ts">
  export { className as class };

  let className = '';
</script>

<svg
  class={className}
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24">
  <path
    d="M2.6 6.42v5.54h6.18V6.42h1.58V19H8.78v-5.68H2.6V19H1.02V6.42H2.6zm18.119 8.5c0-1.587-1.033-2.38-3.1-2.38h-3.38l.54-6.12h6.98v1.44h-5.46l-.34 3.62c.693-.12 1.353-.18 1.98-.18 1.373 0 2.453.307 3.24.92.787.6 1.18 1.513 1.18 2.74 0 1.227-.413 2.247-1.24 3.06-.827.8-1.893 1.2-3.2 1.2-1.307 0-2.373-.327-3.2-.98-.827-.667-1.24-1.58-1.24-2.74h1.58c0 1.093.56 1.807 1.68 2.14.347.093.727.14 1.14.14.853 0 1.54-.267 2.06-.8.52-.547.78-1.233.78-2.06z" />
</svg>
