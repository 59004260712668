<script lang="ts">
</script>

<div class="bcmsGlow">
  <div class="bcmsGlow--inner">
    <img data-bcms-glow="yellow-green" loading="lazy" src="/assets/gradients/yellow-green.png" alt="" />
    <img data-bcms-glow="pink-yellow" loading="lazy" src="/assets/gradients/pink-yellow.png" alt="" />
    <img data-bcms-glow="pink-yellow-green" loading="lazy" src="/assets/gradients/pink-yellow-green.png" alt="" />
    <img data-bcms-glow="pink-green" loading="lazy" src="/assets/gradients/pink-green.png" alt="" />
    <img data-bcms-glow="green-yellow" loading="lazy" src="/assets/gradients/green-yellow.png" alt="" />
  </div>
</div>
